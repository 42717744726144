<template>
  <div class="row">
    <v-update-user-detail :show-modal="showModalUserUpdate" v-on:closemodal="closemodal" :edit="edit"
      :location_id="location_id" :user-details="updateUserDetail"></v-update-user-detail>
    <div class="full-load" v-if="resetPassowordStatus">
      <h1>
        Sending reset password link to <br />
        <strong>{{ email }}</strong> <br />
        email address
      </h1>
    </div>
    <div class="col-md-12" style="background:white">
      <table class="table table-striped">
        <thead>
          <tr>
            <th colspan="1">
              <input type="text" autocomplete="false" v-model="searchInput" class="form-control"
                placeholder="Search User" />
            </th>
            <th colspan="2"></th>
            <th colspan="3">
              <button class="btn btn-success mr-3" style="float: right" @click="openmodel">
                <i class="fa fa-plus"></i>
                Add User
              </button>
              <button ref="kt_save_add_another_company" class="btn btn-danger mr-3" @click="goBack()"
                style="float: right">
                <i class="fa fa-arrow-left"></i> Back
              </button>
            </th>
          </tr>

          <tr>
            <th>SN</th>
            <th>Name</th>
            <th>Email</th>
            <th>Username</th>
            <th>Status</th>
            <th style="width: 21%;"></th>
          </tr>
        </thead>
        <tbody v-if="!loadingData && filteredUsers.length > 0">
          <tr v-for="(item, index) in filteredUsers" :key="index">
            <td>{{ ++index }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.username }}</td>
            <td>
              <div class="form-group">
                <span class="switch">
                  <input type="checkbox" v-model="item.is_active" class="switch" :id="'switch-normal' + item.id"
                    @change="
                      updateServiceStatus(
                        index - 1,
                        item.id,
                        item.is_active,
                        item.username
                      )
                      " />
                  <label :for="'switch-normal' + item.id"></label>
                </span>
              </div>
            </td>
            <td>
              <!-- <b-dropdown
                id="dropdown-1"
                text="Action"
                class="m-md-2"
                variant="primary"
              >
                <b-dropdown-item
                  @click="sendPassword(item.username, client.slug, item.email)"
                  >Reset password</b-dropdown-item
                >
                <b-dropdown-item @click="setPassword(item.id, item.username)"
                  >Set password</b-dropdown-item
                >
                <b-dropdown-item @click="openUserUpdateModal(item)"
                  >Edit</b-dropdown-item
                >
                <b-dropdown-item @click="deleteItem(item.id)"
                  >Delete</b-dropdown-item
                >
              </b-dropdown> -->
              <button @click="sendPassword(item.username, client.slug, item.email)" class="btn btn-sm btn-info mr-3">
                Reset password
              </button>
              <button @click="openUserUpdateModal(item)" class="btn btn-sm btn-primary  mr-3 ">
                Edit
              </button>
              <button @click="setPassword(item.id, item.username)" class="btn btn-sm btn-success mr-3 mt-2">
                Set password
              </button>

              <button @click="deleteItem(item.id)" class="btn btn-sm btn-danger mr-3 mt-2" style="cursor: pointer;">
                Delete
              </button>
              <button v-if="item.is_active && currentUser.u.is_admin == 1" class="btn btn-primary btn-sm mt-2" @click="impersonateUser(item)">
                Login as
              </button>
            </td>
          </tr>
        </tbody>

        <tbody v-if="!loadingData && filteredUsers.length < 1">
          <tr>
            <td colspan="8" class="text-center">No record(s) found</td>
          </tr>
        </tbody>

        <tbody v-if="loadingData">
          <tr>
            <th class="text-center" colspan="10">
              <vDataLoader></vDataLoader>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import {
  FETCH_B_LOCATION_USER,
  RESET_USER_PASSWORD,
  UPDATE_B_USER,
  EMAIL_PASSWORD_B_USER,
  DELETE_B_USER,
  FETCH_ROLES,
  IMPERSONATE_USER,
  LOGOUT,
} from "@/core/services/store/actions.type";
import vUpdateUserDetail from "@/components/frontend/modal/updateuserdetail";
import { mapGetters } from "vuex";
import vDataLoader from "@/components/frontend/spinner.vue";
export default {
  data() {
    return {
      rules: [
        { message: "One lowercase letter required.", regex: /[a-z]+/ },
        { message: "One uppercase letter required.", regex: /[A-Z]+/ },
        { message: "8 characters minimum.", regex: /.{8,}/ },
        { message: "One number required.", regex: /[0-9]+/ },
        { message: "One special character required.", regex: /[!@#$%^&*]+/ }
      ],
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Locations",
          route: "client.location"
        },
        {
          id: 3,
          title: "Users",
          route: ""
        }
      ],
      loadingData: false,
      searchInput: "",
      users: [],
      client: [],
      resetPassowordStatus: "",
      email: "",
      showModalUserUpdate: false,
      updateUserDetail: {},
      edit: true,
      location_id: ""
    };
  },
  components: {
    vDataLoader,
    vUpdateUserDetail
  },
  computed: {
    ...mapGetters(["currentUser", "getRoleData"]),
    filteredUsers() {
      let tempUser = this.users;
      //process search input
      if (this.searchInput != "" && this.searchInput) {
        tempUser = tempUser.filter(item => {
          return item.name
            .toUpperCase()
            .includes(this.searchInput.toUpperCase());
        });
      }
      return tempUser;
    }
  },
  created() {
    this.breadCrumbs[1].title = "Service Provider Locations";
    this.breadCrumbs[1].route = "admin.serviceprovider.index";
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.getUserData();
    this.$store.dispatch(FETCH_ROLES);
    this.location_id = parseInt(this.$route.params.id);
  },
  methods: {
    closemodal(status) {
      this.showModalUserUpdate = false;
      if (status) {
        this.getUserData();
      }
    },
    deleteItem(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this User data!",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes"
      }).then(willDelete => {
        if (willDelete.isConfirmed) {
          this.$store.dispatch(DELETE_B_USER, id).then(data => {
            this.$toastr.s(data.msg);
            // this.filteredUsers.splice(index, 1);
            this.getUserData();
          });
        }
      });
    },
    openmodel() {
      this.updateUserDetail = {
        first_name: "",
        last_name: "",
        email: "",
        username: "",
        name: "",
        roles: "staff",
        user_role: ""
      };
      this.showModalUserUpdate = true;
      this.edit = false;
    },
    openUserUpdateModal(data) {
      this.updateUserDetail = data;
      this.showModalUserUpdate = true;
      this.edit = true;
    },
    goBack() {
      if (this.$route.name == "admin.serviceprovider.location.users") {
        this.$router.push({
          name: "admin.serviceprovider.index"
        });
      } else {
        var client_slug = this.$route.params.client_slug;
        this.$router.push({
          name: "client.location",
          params: { client_slug: client_slug }
        });
      }
    },
    async setPassword(id = 0, username = "") {
      const { value: password } = await this.$swal.fire({
        title: "Setting new password for user <b>" + username + "</b>",
        input: "password",
        inputPlaceholder: "Enter new password",
        confirmButtonText: "Save",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        showCloseButton: true,
        everseButtons: true,
        inputValidator: value => {
          if (!value) {
            return "Please enter password!";
          } else {
            for (let condition of this.rules) {
              if (!condition.regex.test(value)) {
                return condition.message;
              }
            }
          }
        }
      });

      if (password) {
        this.$store
          .dispatch(EMAIL_PASSWORD_B_USER, {
            user_id: id,
            password: password
          })
          .then(data => {
            this.$toastr.s(data.msg);
            this.$swal.fire(`New Password has been set to user ${username}`);
            // this.closeBtnLoad(ref);
          })
          .catch(err => {
            this.errors = err;
            this.$toastr.e(this.errors.error.password);
            //  this.$swal.fire(err);
            // this.closeBtnLoad(ref);
          });
      }
    },
    loadingButton(ref) {
      const submitButton = this.$refs[ref];

      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },
    closeBtnLoad(ref) {
      const submitButton = this.$refs[ref];

      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
      submitButton.disabled = false;
    },
    updateServiceStatus(index, id, flag, username) {
      this.errors = [];
      var flag_ = flag ? false : true;
      var _data = {
        is_active: flag,
        status_: true
      };
      this.$swal
        .fire({
          title: "Are you sure?",
          text:
            "Would you like to change the status of user: " + username + "?",
          confirmButtonText: "Save",
          cancelButtonText: "Cancel",
          showCancelButton: true,
          showCloseButton: true,
          everseButtons: true
        })
        .then(changeStatus => {
          if (changeStatus.isConfirmed) {
            this.$store
              .dispatch(UPDATE_B_USER, {
                id: id,
                data: _data
              })
              .then(data => {
                this.$toastr.s(data.msg);
                // this.closeBtnLoad(ref);
              })
              .catch(err => {
                this.errors = err;
                // this.closeBtnLoad(ref);
              });
          } else {
            this.filteredUsers[index].is_active = flag_;
          }
        });

      // this.loadingButton(ref);
    },
    sendPassword(username, slug, email) {
      var that = this;
      //   var client_slug = this.$route.params.client_slug;
      that
        .$swal({
          title: "Are you sure to reset password of user: " + username + " ?",
          text: "New Password will be sent to " + email,
          icon: "Info",
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          showCancelButton: true,
          showCloseButton: true,
          everseButtons: true
        })
        .then(sendPassword => {
          if (sendPassword.isConfirmed) {
            that.email = email;
            that.resetPassowordStatus = true;
            that.$store
              .dispatch(RESET_USER_PASSWORD, {
                email: email,
                client_slug: slug
              })
              .then(data => {
                that.resetPassowordStatus = false;
                this.$toastr.s(data.msg);
                // that.closeBtnLoad(ref_);
              })
              .catch(() => {
                that.resetPassowordStatus = false;
                // that.closeBtnLoad(ref_);
              });
          }
        });
    },

    getUserData() {
      this.loadingData = true;
      this.$store
        .dispatch(FETCH_B_LOCATION_USER, this.$route.params.id)
        .then(data => {
          this.client = data.client;
          this.users = data.users;

          this.breadCrumbs[2].title = "Users | " + this.client.name;

          this.loadingData = false;
        })
        .catch(() => {
          this.loadingData = false;
        });
    },
    impersonateUser(item) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text:
            "Would you like to login as User: " + item.name + "?",
          confirmButtonText: "Login",
          cancelButtonText: "Cancel",
          showCancelButton: true,
          showCloseButton: true,
          everseButtons: true
        })
        .then(swalStatus => {
          console.log("YEAHHH", swalStatus)
          if (swalStatus.isConfirmed) {
            // clear existing errors
            // this.$store.dispatch(LOGOUT);
            this.$store
              .dispatch(IMPERSONATE_USER, { user_id: item.id })
              .then((data) => {
                // Log before the push to confirm it happens
                console.log("Navigating to", { name: "client.dashboard", params: { client_slug: data.slug } });
                console.log("data.slugggg", data.slug, this.$route.params.client_slug, "Current slug")

                if (data.slug){
                  console.log("dddata.slug", data.slug)
                  this.$router.push({
                  name: "client.dashboard",
                  params: { client_slug: data.slug }
                })
                  .then(() => {
                    // we need to refresh the page as old components are being used when admin impersonates the user.
                    console.log("gone to page and reloaded");
                    window.location.reload();
                  });
                }
                
              });
          }
        });
    },
  }
};
</script>

<style></style>
